<template>
  <div
    class="w-[100%] md:w-[50%] h-full p-[45px] md:p-[60px] md:pt-32 sm:pt-9 flex justify-center items-start align-middle"
  >
    <div>
      <EventDetails :title="eventDetails.title" :description="eventDetails.description" />
      <SuccessfulRegistration
        v-if="state === EventRegistrationState.EventRegistrationDone"
        sub-title="Congratulations!"
        :description="$t(`components.events.eventInfo.eventRegistrationDone`)"
      />
      <LoginSignupPage v-if="state === EventRegistrationState.UserIsNotLoggedIn" :utm-source="eventDetails.utmSource" />
      <EventInfo
        v-else-if="state === EventRegistrationState.EventNotLiveYet"
        data-testid="event-not-live-yet-message"
        :event-information="$t(`components.events.eventInfo.eventNotLiveYet`)"
        connotation="information"
        icon="error-line"
      />
      <EventInfo
        v-else-if="state === EventRegistrationState.EventEnded"
        data-testid="event-ended-message"
        :event-information="$t(`components.events.eventInfo.eventEnded`)"
        connotation="information"
        icon="error-line"
      />
      <EventRegistrationRegisterForm
        v-else-if="state === EventRegistrationState.EventRegistrationReady"
        :event-title="eventDetails.title"
        :event-description="eventDetails.description"
        :term="eventDetails.termsAndConditions"
        @register="registerForEvent()"
      />
      <EventInfo
        v-else-if="state === EventRegistrationState.UserIsAlreadyRegistered"
        data-testid="user-already-registered-message"
        :event-information="$t(`components.events.eventInfo.userIsAlreadyRegistered`)"
        connotation="success"
        icon="check-circle-line"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { EventRegistrationState, getEventRegistrationState } from '@/utils/eventRegistration';
import type { EventRegistration } from '@/types/storyblok';
import { type PropType, ref } from 'vue';
import { registerUserForEvent } from '@/api';
import SuccessfulRegistration from '@/components/pages/event-registration/forms/SuccessfulRegistration.vue';
import EventDetails from '@/components/pages/event-registration/forms/EventDetails.vue';
import LoginSignupPage from '@/components/pages/event-registration/forms/LoginSignupPage.vue';
import EventInfo from '@/components/pages/event-registration/forms/EventInfo.vue';
import EventRegistrationRegisterForm from '@/components/pages/event-registration/forms/EventRegistrationRegisterForm.vue';
import { useUserStore } from '@/store/user';

const props = defineProps({
  eventId: { type: String, required: true },
  eventDetails: { type: Object as PropType<EventRegistration>, required: true },
});

const user = useUserStore();

const state = ref(await getEventRegistrationState(user.info as API.UserInfo, props.eventId, props.eventDetails));

async function registerForEvent() {
  await registerUserForEvent(props.eventId);
  state.value = EventRegistrationState.EventRegistrationDone;
}
</script>
