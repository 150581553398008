<template>
  <div class="event-registration-container">
    <div class="mt-6 mb-8 flex text-xs items-center font-normal leading-4">
      <VCheckbox v-model="agreeToTerms" data-testid="agree-to-terms-checkbox" />
      <p class="ml-2 mb-0 mt-0 content">
        I agree to Vonage's
        <span class="btn-link underline cursor-pointer" @click="openDialog">Terms & Conditions</span>
      </p>
    </div>
    <VButton
      appearance="filled"
      data-testid="register-button"
      label="Register"
      connotation="cta"
      :disabled="!agreeToTerms"
      @click="handleEventRegisteration"
    />
    <terms-conditions-dialog-box
      :headline="$t(`components.events.termsAndConditions.headline`)"
      :subtitle="$t(`components.events.termsAndConditions.subtitle`)"
      :body-header="$t(`components.events.termsAndConditions.bodyHeader`)"
      :body-description="term"
      :action-label="$t(`components.events.termsAndConditions.actionLabel`)"
      :open="showDialogBox"
      @close="showDialogBox = false"
      @open-dialog="openDialog"
    />
  </div>
</template>
<script setup lang="ts">
import { VButton, VCheckbox } from '@vonage/vivid-vue';
import { ref } from 'vue';
import TermsConditionsDialogBox from './TermsConditionsDialogBox.vue';

defineProps({
  eventTitle: { type: String, required: true },
  eventDescription: {
    type: String,
    required: true,
  },
  term: { type: String, required: true },
});

const emit = defineEmits(['register']);

const agreeToTerms = ref(false);
const showDialogBox = ref(false);
function openDialog() {
  showDialogBox.value = true;
}

function handleEventRegisteration() {
  emit('register');
}
</script>
<style scoped lang="scss">
.event-registration-container {
  color: #2c2d30;
  .header {
    font-size: 32px;
    line-height: 44px;
  }
  .content {
    font: var(--vvd-typography-base-extended);
  }
  .btn-link {
    color: var(--palette-purple-600, #871eff);
  }
}
</style>
