<template>
  <div :class="{ hidden }">
    <VDialog
      ref="dialog"
      :headline="headline"
      :subtitle="subtitle"
      icon-placement="side"
      :open="open"
      @close="closePopup"
    >
      <template #graphic>
        <v-icon :style="{ color: '#30A849' }" class="mt-2" name="hand-shake-solid" :size="-3" />
      </template>
      <template #body>
        <div class="dialog-box-body flex items-start justify-center self-stretch flex-col font-normal mt-6">
          <p class="body-description text-xs leading-4 py-2 px-4 w-[100%] h-[100%] overflow-x-auto overflow-y-auto">
            <RichText :text="bodyDescription" />
          </p>
        </div>
      </template>
      <template #action-items>
        <VButton appearance="outlined" :label="actionLabel" @click="closePopup" />
      </template>
    </VDialog>
  </div>
</template>
<script setup lang="ts">
import { VButton, VDialog, VIcon } from '@vonage/vivid-vue';
import { onMounted, type PropType, ref, watch } from 'vue';
import type { ISbRichtext } from '@storyblok/js';
import RichText from '@/components/rich-text/RichText.vue';

const nuxtApp = useNuxtApp();
const props = defineProps({
  open: { type: Boolean, required: true },
  headline: { type: String, required: true },
  subtitle: { type: String, required: true },
  bodyHeader: { type: String, required: true },
  bodyDescription: { type: Object as PropType<ISbRichtext>, required: true },
  actionLabel: { type: String, required: true },
});

const emit = defineEmits(['close', 'open-dialog']);
const dialog = ref<typeof VDialog | undefined>();

const closePopup = () => {
  dialog.value.$el.returnValue = 'Value';
  dialog.value.close();
  emit('close');
};

const hidden = ref(true);

onMounted(() =>
  watch(
    () => props.open,
    newVal => {
      if (newVal) {
        dialog.value.showModal();
      }
    }
  )
);

nuxtApp.hook('page:finish', () => {
  hidden.value = false;
});
</script>
<style scoped lang="scss">
.dialog-box-body {
  border: 1px solid var(--palette-accent-200, #ccc);

  .body-header {
    color: var(--palette-neutral-950, #0d0d0d);
  }

  .body-description {
    color: var(--palette-neutral-700, #4d4d4d);
  }
}
</style>
