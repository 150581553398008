<template>
  <GenericPage>
    <div class="flex md:flex-row flex-col h-full container">
      <EventRegistrationBanner :event-details="story.content" />
      <EventRegistrationForm :event-id="eventId" :event-details="story.content" />
    </div>
  </GenericPage>
</template>

<script setup lang="ts">
import GenericPage from '@/components/pages/GenericPage.vue';
import EventRegistrationBanner from '@/components/pages/event-registration/EventRegistrationBanner.vue';
import EventRegistrationForm from '@/components/pages/event-registration/EventRegistrationForm.vue';
import useAsyncStoryblokWithI18n from '@/utils/storyblok/useAsycStoryblokWithI18n';
import type { EventRegistrationStory } from '@/types/storyblok';

const props = defineProps({
  eventId: { type: String, required: true },
});

const story = await useAsyncStoryblokWithI18n<EventRegistrationStory>(`events/register/${props.eventId}`);
</script>
