<template>
  <div class="flex flex-row">
    <a data-testid="sign-up-url" :href="signUpUrl" type="button" class="vvd-scheme-main mr-8">
      <VButton data-testid="sign-up-button" label="Sign Up" appearance="filled" connotation="cta" class="block" />
    </a>
    <a :href="signInUrl" type="button">
      <VButton data-testid="sign-in-button" label="Sign In" appearance="ghost" connotation="cta" class="block" />
    </a>
  </div>
</template>

<script setup lang="ts">
import { VButton } from '@vonage/vivid-vue';
import { useRoute } from 'vue-router';
import { computed } from 'vue';

const route = useRoute();

const props = defineProps({
  utmSource: { type: String, required: false },
});

const signInUrl = computed(() => (route ? `/sign-in?redirect=${encodeURI(route.fullPath)}` : ''));
const signUpUrl = computed(() => {
  if (route) {
    return props.utmSource
      ? `/sign-up?utm_source=${props.utmSource}&redirect=${encodeURI(route.fullPath)}`
      : `/sign-up?redirect=${encodeURI(route.fullPath)}`;
  }
  return '';
});
</script>
